import styled from "styled-components";
import { media } from "../../lib/theme";

const AccordionWrapper = styled.div`
  .reusecore__accordion {
    max-width: 820px;
    margin: 0 auto;
    padding: 1rem 1.5rem;
    border-bottom: 1px solid #ebebeb;
    border-radius: 10px;
    background: ${props => props.theme.colors.background};
    overflow: hidden;
    box-shadow: 0px 5px 60px 0px rgba(27, 67, 111, 0.15);

    ${media.lessThan("small")} {
      padding: 1rem 0;
    }

    .accordion__item {
      border-top: 0;
      border-bottom: 1px solid #ebebeb;
      &:last-child {
        border-bottom: 0;
      }

      .accordion__header {
        padding: 20px 30px;

        &:has(div[aria-expanded="true"]) {
          background: rgba(0, 0, 0, 0.2);
        }
      }

      .accordion__body {
        padding: 5px 30px 20px;
      }
    }
  }
`;

export { AccordionWrapper };
