import { graphql } from "gatsby";
import React from "react";
import Fade from "react-reveal/Fade";
import { Heading } from "rebass";
import CallToAction from "../components/CallToAction";
import Layout from "../components/Layout";
import PageHeader from "../components/PageHeader";
import SEO from "../components/Seo";
import FaqSection from "../container/FaqSection";

const FaqPage = ({ data }) => {
  return (
    <Layout transparentHeader>
      <SEO title="常見問題" />
      <PageHeader backgroundImage={data.bg.childImageSharp.fluid}>
        <Fade delay={100}>
          <Heading color="white" fontSize={[5, 7]}>
            常見問題
          </Heading>
        </Fade>
      </PageHeader>

      <FaqSection />

      <CallToAction />
    </Layout>
  );
};

export default FaqPage;

export const query = graphql`
  {
    bg: file(relativePath: { eq: "bg-6.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
