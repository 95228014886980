import React, { FC } from "react";
import "react-accessible-accordion/dist/fancy-example.css";
import {
  AccordionBodyWrapper,
  AccordionItemButtonWrapper,
  AccordionItemWrapper,
  AccordionTitleWrapper,
  AccordionWrapper,
  CloseIcon,
  IconWrapper,
  OpenIcon
} from "./accordion.style";

type AccordionProps = {
  className?: string;
  allowZeroExpanded?: boolean;
};

const Accordion: FC<AccordionProps> = ({
  className,
  children,
  allowZeroExpanded = true
}) => {
  // Add all classs to an array
  const addAllClasses = ["reusecore__accordion"];

  // className prop checking
  if (className) {
    addAllClasses.push(className);
  }

  return (
    <AccordionWrapper
      allowZeroExpanded={allowZeroExpanded}
      className={addAllClasses.join(" ")}
    >
      {children}
    </AccordionWrapper>
  );
};

type AccordionItemProps = {
  className?: string;
  expanded?: boolean;
};

const AccordionItem: FC<AccordionItemProps> = ({
  className,
  children,
  expanded
}) => {
  // Add all classs to an array
  const addAllClasses = ["accordion__item"];

  // className prop checking
  if (className) {
    addAllClasses.push(className);
  }

  return (
    <AccordionItemWrapper className={addAllClasses.join(" ")}>
      {children}
    </AccordionItemWrapper>
  );
};

type AccordionTitleProps = {
  className?: string;
};

const AccordionTitle: FC<AccordionTitleProps> = ({ className, children }) => {
  // Add all classs to an array
  const addAllClasses = ["accordion__header"];

  // className prop checking
  if (className) {
    addAllClasses.push(className);
  }

  return (
    <AccordionTitleWrapper className={addAllClasses.join(" ")}>
      <AccordionItemButtonWrapper>{children}</AccordionItemButtonWrapper>
    </AccordionTitleWrapper>
  );
};

type AccordionBodyProps = {
  className?: string;
};

const AccordionBody: FC<AccordionBodyProps> = ({ className, children }) => {
  // Add all classs to an array
  const addAllClasses = ["accordion__body"];

  // className prop checking
  if (className) {
    addAllClasses.push(className);
  }

  return (
    <AccordionBodyWrapper className={addAllClasses.join(" ")}>
      {children}
    </AccordionBodyWrapper>
  );
};

export {
  Accordion,
  AccordionItem,
  AccordionTitle,
  AccordionBody,
  IconWrapper,
  OpenIcon,
  CloseIcon
};
