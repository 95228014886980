import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Icon } from "react-icons-kit"
import { minus } from "react-icons-kit/entypo/minus"
import { plus } from "react-icons-kit/entypo/plus"
import { Text } from "rebass"
import {
  Accordion,
  AccordionBody,
  AccordionItem,
  AccordionTitle,
  CloseIcon,
  IconWrapper,
  OpenIcon,
} from "../../components/Accordion"
import MarkdownContent from "../../components/Markdown"
import Section from "../../components/Section"
import { AccordionWrapper } from "./faqSection.style"

const FaqSection = () => {
  const { markdown } = useStaticQuery(graphql`
    {
      markdown: markdownRemark(fileAbsolutePath: { regex: "/questions/" }) {
        frontmatter {
          title
          section {
            title
            questions {
              question
              answer
            }
          }
        }
      }
    }
  `)
  return (
    <AccordionWrapper>
      {markdown.frontmatter.section.map((s, i) => (
        <Section
          key={`faq-section-${i}`}
          title={s.title}
          px={[2]}
          bg={i % 2 === 0 ? null : "secondary"}
        >
          <Accordion>
            {s.questions.map(({ question, answer }, i) => (
              <AccordionItem key={`faq-section-accordion-${i}`}>
                <AccordionTitle>
                  <Text fontSize={[2, 3]} fontWeight="bold">
                    {question}
                  </Text>
                  <IconWrapper>
                    <OpenIcon>
                      <Icon
                        icon={minus}
                        size={28}
                        css={`
                          color: ${props => props.theme.colors.info};
                        `}
                      />
                    </OpenIcon>
                    <CloseIcon>
                      <Icon
                        icon={plus}
                        size={28}
                        css={`
                          color: ${props => props.theme.colors.info};
                        `}
                      />
                    </CloseIcon>
                  </IconWrapper>
                </AccordionTitle>
                <AccordionBody key={`faq-questions-${i}`}>
                  <MarkdownContent content={answer} />
                </AccordionBody>
              </AccordionItem>
            ))}
          </Accordion>
        </Section>
      ))}
    </AccordionWrapper>
  )
}

export default FaqSection
